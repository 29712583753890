import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import strategic_planing_pyramind from '../../../images/pages/strategic_planing_pyramind.png'
import title_image from '../../../images/titles/vision_and_strategy_development.jpg'
import person from '../../../images/person.png'

const pageTitle = "Vision and Strategy Development";
const pageSLug = "vision-and-strategy-management";
class VisionStrategy extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Our experts take a practical, execution-oriented approach towards developing your strategy.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Developing your strategy and vision is a crucial business function that will allow you to assess 
                    opportunities and issues. As well as discuss priorities and ensure strategic alignment throughout 
                    your organization.</h3>
                  <span className="break"></span>
                  <p>Strategic plans serve as a living document that adapts and evolves to fit current market conditions. 
                      Through our experiences, companies that are unable to make strategic decisions and turn their 
                      strategy into action underperform. For if you don't communicate your direction correctly, you could 
                      suffer from a  slow reaction time, and poor accountability.</p>
                   <h3 className="intext">Together we can help you solve these issues.</h3>
                   <p>Not sure where to start? Together we can figure that out.</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                   <span className="break"></span>
                   <h4>When Strategy Development fails</h4>
                   <p>When an organization is without vision and strategy development, they can lose focus on their core business 
                       goals, and suffer from lackluster leadership.</p>
                    <p>Strategy and vision development has undergone significant change within companies over the past several years. 
                        Achieving success in today's market requires a strategic plan and a vision that is:</p>
                    <ul>
                        <li>Fact-based</li>
                        <li>Inspiring and motivating</li>
                        <li>Allows downstream decision-making</li>
                        <li>Is continuous, agile and adaptive</li>
                        <li>States necessary capabilities and investments required</li>
                        <li>Provides internal and external knowledge</li>
                        <li>Adaptive, agile and continuous</li>
                        <li>Created from inclusive and participatory planning</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Nuerex's Approach to Strategy Development</h4>
                    <p>Our experts can ensure strategic purpose and alignment.</p>
                    <p>Our approach ensures that short term priorities align with long term goals, vision, and aspirations. 
                        By using business analytics and market research, we identify key issues, opportunities, and threats 
                        that impact an organization's business model.</p>
                    <p>Together we refine your organization's core purpose, mission, and vision, and build critical strategies 
                        that are required to get there. Then we begin to plan, organize, evaluate, and prioritize relevant 
                        strategies into a roadmap that contains your short term, mid-term, and long term goals and obstacles.</p>
                    <p>1. Core Purpose & Values:  Understanding why the business exists and the way it operates.</p>
                    <p>2 .Envisioned Future & Goal management:  Envisioning an inspirational future along with a motivating target.</p>
                    <p>3. 3 to 5 Year Strategic obstacles:  Identifying the strategic barriers that must be overcome to achieve the goal.</p>
                    <p>4. Multi-Year Roadmap:  Defining the solutions that are needed to effectively organize the strategies over the next 3 to 5 years.</p>
                    <p>5. Annual Planning:  Begin developing a yearly plan including critical solutions, priorities, and ownership</p>
                    <img src={strategic_planing_pyramind} />
                    <h4>From the Beginning to the End</h4>
                    <p>Developing the necessary business strategy is only half the battle. Prioritizing and defining the key initiatives 
                        that are needed to get there, then actively managing these initiatives is a crucial step to success. Deploying 
                        and implementing these strategies is just as essential and in many cases, even more so.</p>
                    <h3 className="intext">By working together, we can successfully enable, manage, deploy, and sustain change.</h3>
                    <span className="break"></span>
                    <h4>Product and Services Strategies</h4>
                    <ul>
                    <li>How should products be managed and segmented, to align with strategic purpose and profitability?</li>
                    <li>How can we add innovation, agility, and responsiveness to the product development process?</li>
                    <li>How are current product portfolios being aligned to match the market needs and competitor offerings?</li>
                    </ul>
                   
                    <span className="break"></span>
                    <h4>Customer and Channel Strategy</h4>
                    <ul>
                        <li>Are customers satisfied with products, prices, and services?</li>
                        <li>What driving factors affect profitability in each channel?</li>
                        <li>How can efficiency be boosted within each channel using organizational 
                            support and technology? How can this growth be increased?</li>
                        <li>Which channels offer the most significant opportunity for growth?</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Operating Strategy</h4>
                    <ul>
                        <li>What is the nature of the required change?</li>
                        <li>What should our shared service strategy be?</li>
                        <li>What are the core competencies of the organization?</li>
                        <li>Which operating capabilities are impacted the most by the various 
                            policies around product, growth, channel, and customer focus?</li>
                    </ul>
                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>Interested in how strategy and vision development can help develop your business?</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Contact us today</Link>
                        </div>
                    </div>
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                       
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default VisionStrategy
